.selected-item {
  text-align: center;
}

.controls-container {
  --highlight-width: auto;
  --highlight-x-pos: 0;

  display: flex;
  margin: 32px 0 25px;
  font-family: Open Sans;
  line-height: 1.235;
}

.controls {
  display: inline-flex;
  justify-content: space-between;
  background: #0000001a;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 700px;
  padding: 4px 6px;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.controls input {
  opacity: 0;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  cursor: pointer;
  height: 100%;
}

.segment {
  /* width: 100%; uncomment for each segment to have matching width */
  width: 100%;
  min-width: 200px;
  position: relative;
  text-align: center;
  z-index: 1;
}

.segment label {
  cursor: pointer;
  display: block;
  font-weight: 300;
  padding: 20px 30px;
  font-size: 16px;
  transition: color 0.5s ease;
  white-space: nowrap;
}

.segment.active label {
  color: black;
  font-weight: 600;
}

.controls::before {
  content: '';
  background: white;

  border-radius: 16px;
  width: var(--highlight-width);
  transform: translateX(var(--highlight-x-pos));
  position: absolute;
  top: 8px;
  bottom: 8px;
  left: 0;
  z-index: 0;
}

/* Only allow transitions once component is ready */
.controls.ready::before {
  transition: transform 0.3s ease, width 0.3s ease;
}

.rt {
  color: red;
  background: red;
  border-radius: 100px;
  text-align: center;
  font-size: 14px;
  height: 6px;
  width: 6px;
  position: absolute;
  top: 8px;
  right: 26px;
  // margin-right: 4px;
  z-index: 10000;
  content: '.';
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  /* visibility: hidden; */
}
