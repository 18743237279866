@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.pill {
  font-family: 'Open Sans';
  font-size: 15px;
  white-space: nowrap;
  .pill-content {
    border-radius: 0.5rem;
    // border: 1px solid #6e6e6e;
    margin-bottom: 0;
    margin-top: 0.3rem;
    font-weight: 600;
    .pill-left {
      border: 1px solid $black-10;
      border-right: 0;
      border-bottom-left-radius: 0.5rem;
      border-top-left-radius: 0.5rem;
      padding-right: 0.4rem;
      padding-left: 0.4rem;
      display: inline-block;
      color: #507ace;
      background-color: #507ace1a;
    }

    .pill-right {
      border: 1px solid $black-10;
      border-left: 0;
      border-bottom-right-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      padding-right: 0.4rem;
      padding-left: 0.4rem;
      display: inline-block;
      color: $black-75;
      background-color: #ffffff;
    }
  }
}

[role='dialog'] {
  border-radius: 16px;
}

.border-top {
  border-top: 1px solid $black-10 !important;
}

.border-bottom {
  border-bottom: 1px solid $black-10;
}

.guest-renew-review-list:last-child {
  border-bottom: none !important;
}

.Mui-selected {
  background-color: red;
}
