.b-75 {
  color: $black-75 !important;
}

.b-50 {
  color: $black-50 !important;
}

.b-10 {
  color: $black-10 !important;
}

// .h6 {
//   margin-bottom: 8px !important;
// }
