.mt-sm {
  margin-top: $margin-small;
}

.mb-sm {
  margin-bottom: $margin-small;
}

.mt-lg {
  margin-top: $margin-large !important;
}

.mb-lg {
  margin-bottom: $margin-large !important;
}

.pt-sm {
  padding-top: $margin-small;
}

.pb-sm {
  padding-bottom: $margin-small;
}

.pt-lg {
  padding-top: $margin-large !important;
}

.pb-lg {
  padding-bottom: $margin-large !important;
}

.br-16 {
  border-radius: $border-radius;
}

.mr-sm {
  margin-right: $margin-small;
}

.ml-sm {
  margin-left: $margin-small;
}
